/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.navbar-toggle, #close').click(function(event) {
          $('body').toggleClass('modal');
        });

        $('.slider').slick({
          'autoplay': true,
          'autoplaySpeed': 10000,
          'dots': true,
          'arrows': false,
          'speed': 210,
          'infinite': true,
          'cssEase': 'cubic-bezier(1.000, 0.000, 0.000, 1.000)'
        });


        // window.sr = new ScrollReveal();
        // sr.reveal('.col-content, .reveal', { duration: 2000 }, 50);

        var data = {
          // A labels array that can contain any sort of values
          labels: ['2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016'],
          // Our series array that contains series objects or in this case series data arrays
          series: [
            [200000, 300000, 550000, 600000, 600000, 850000, 950000, 1200000, 1500000]
          ]
        };

        var options = {
          axisY: {
            offset: 60,
            labelInterpolationFnc: function(value) {
              return value + '€';
            }
          }
        };

        // Create a new line chart object where as first parameter we pass in a selector
        // that is resolving to our chart container element. The Second parameter
        // is the actual data object.
        if ($('.ct-chart').length > 0) {
          new Chartist.Line('.ct-chart', data, options);
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
